/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    MENU_HOME: `ön sayfa`,
    MENU_MARKETS: `pazar`,
    MENU_ASSET: `varlıklar`,
    MENU_PLAN: `Sert`,
    MENU_USER_CENTER: `Kişisel Merkez`,
    LOGOUT: `çıkış yapmak`,
    LOGIN: `Giriş yapmak`
};
