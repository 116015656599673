/* eslint-disable */
/**
 * Auto generated file.  DO NOT MODIFY
 **/
export default {
    SECTION_TERMS: `Şartlar Beyanı`,
    TERMS: `Kullanıcı Sözleşmesi`,
    PRIVACY: `gizlilik politikası`,
    COOKIE: `Çerez Politikası`,
    AML: `Kara Para Aklamanın Önlenmesi Politikası`,
    CONDUCT_CODE: `davranış kodu`,
    CUSTOMER_SERVICE: `Müşteri Hizmetleri`,
    HELP_CENTER: `Yardım Merkezi`,
    FEEDBACK: `Geri bildirim`,
    CONTACTS: `Bize Ulaşın`,
    CHARGES: `Masraflar`,
    SUPPORT: `Servis desteği`,
    ABOUT_US: `Hakkımızda`,
    ANNOUNCEMENTS: `resmi açıklama`,
    FAQ: `FAQ`,
    DEPOSIT_WITHDRAW_STATUS: `Para yatırma ve çekme durumu`,
    OFFICIAL_VERIFICATION: `Resmi doğrulama`,
    LANGUAGES: `dil`,
    SERVICE_EMAIL: `Hizmet e-postası`,
    APP_DOWNLOAD: `Uygulama indirme`
};
