import { isDemoAccount } from "../../utils/localData";

/**
 * 资产接口
 */
export default SERVER => ({
  
  /**
   * 现货资产
   * method GET
   */
  SPOT_ASSET: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/coins`,

  /**
   * 期权资产
   * method GET
   */
  OPTIONS_ASSET: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/options`,

  /**
   * 合约资产
   * method GET
   */
  CONTRACT_ASSET: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/contract`,

  /**
   * 资产划转
   * method POST
   */
  ASSET_ACCOUNT_TRANSFER: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/transfer`,

  /**
   * 充值
   * method POST
   */
  ASSET_RECHARGE: `${SERVER}/api/asset/recharge`,

  /**
   * 充值记录
   * method GET
   */
  ASSET_RECHARGE_RECORD_LIST: `${SERVER}/api/asset/recharge/list`,

  /**
   * 提现配置
   * method GET
   */
  WITHDRAW_CONFIG: `${SERVER}/api/config/despoit`,

  /**
   * 提现
   * method POST
   */
  ASSET_WITHDRAW: `${SERVER}/api/asset/despoit`,

  /**
   * 提现记录
   * method GET
   */
  ASSET_WITHDRAW_RECORD_LIST: `${SERVER}/api/asset/despoit/list`,

  /**
   * 取消提现订单
   * method POST
   */
  ASSET_CANCEL_WITHDRAW: `${SERVER}/api/asset/despoit/cancel`,

  /**
   * 现货流水
   * method GET
   */
  ASSET_SPOT_RECORD_LIST: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/coin/flows`,

  /**
   * 币币划转
   * method POST
   */
  ASSET_COIN_EXCHANGE: `${SERVER}/api/${isDemoAccount()?'test/':''}asset/exchange`,
  
});
